import React, { useState, useEffect, useContext } from 'react';
import { shape, string } from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { displayComponent } from './dataService';
import {
  setCheckoutAnalytics,
  publishEvent,
  isCheckoutPage
} from './util';
import './checkout-prompts.scss';

const CheckoutPrompts = ({
  parentDisplayInfo
}) => {
  const {
    channel,
    customer
  } = useContext(ExperienceContext);

  const checkoutPromptsKillSwitch = useConfigService('fs:checkoutPromptsKillSwitch');
  const shouldRender = (
    channel === 'mobile'
    && customer?.type === 'b2c'
    && !isCheckoutPage()
    && !checkoutPromptsKillSwitch
  );
  const [displayInfo, setDisplayInfo] = useState(parentDisplayInfo || null);

  useEffect(() => {
    if (!parentDisplayInfo && shouldRender) {
      displayComponent(setDisplayInfo);
    }
  }, []);

  useEffect(() => {
    try {
      const footer = document.getElementById('header-nav-container');
      const prompt = document.getElementById('checkout-prompts-container');
      if (prompt) {
        if (footer) {
          prompt.classList.add('adjust-for-footer');
        } else {
          prompt.classList.remove('adjust-for-footer');
        }
      }
    } catch (err) {
      console.error(err);
    }
  });

  return (
    <>
      {(parentDisplayInfo || (shouldRender && (displayInfo && !displayInfo.displayedTime)))
        ? (
          <div
            id="checkout-prompts-container"
            className="container grid checkout-prompts-container"
            data-component="CheckoutPrompts"
          >
            <div className="content">
              <div>
                <img
                  height="71px"
                  width="71px"
                  className="cartImageDesktop"
                  src={displayInfo.image}
                  alt="Product"
                />
              </div>
              <div className="content--text">
                <div className="content--text--holder">
                  <h3 className="content--text--title u__bold">Ready to checkout?</h3>
                  <button
                    type="button"
                    className="thd-react-tooltip__close-button content__close-icon"
                    onClick={() => {
                      publishEvent({
                        name: 'Checkout_Prompt_Dismissed'
                      });
                      setDisplayInfo(null);
                    }}
                  >
                    <img
                      height="20px"
                      width="20px"
                      src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
                      alt="Close"
                    />
                  </button>
                </div>
                <div>{`You have ${displayInfo.cartCount} items in your cart`}</div>
              </div>
            </div>
            <div className="col__12-12">
              <button
                type="submit"
                className="bttn bttn--primary"
                onClick={(event) => {
                  event.preventDefault();
                  setCheckoutAnalytics();
                  window.location.href = '/cart';
                }}
              >
                <span className="bttn__content">Checkout {displayInfo.cartTotal}</span>
              </button>
            </div>
            <div className="col__12-12 terms">Taxes and delivery charges are calculated during checkout</div>
          </div>
        ) : null}
    </>
  );
};

CheckoutPrompts.propTypes = {
  parentDisplayInfo: shape({
    image: string,
    cartCount: string,
    cartTotal: string,
    orderId: string,
    customerId: string,
    userId: string,
  })
};

CheckoutPrompts.defaultProps = {
  parentDisplayInfo: null
};

CheckoutPrompts.displayName = 'CheckoutPrompts';

export { CheckoutPrompts };
